<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" v-if="planSelected == null & planData == null">
            <h2>Assinatura</h2>
          </v-col>
          <v-col v-if="planSelected == null & planData == null">
            <CardPlans :renew="false" @selectPlan="selectPlan" />
          </v-col>
          <v-col v-if="planSelected != null & planData == null">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <h2>Resumo do pedido</h2>
                  <p>Confira abaixo os valores cobrados</p>

                  <div class="card-resume">
                    <h2>{{ planSelected.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h2>
                    <h3>{{ planSelected.type }}</h3>
                  </div>
                </v-col>
                <v-col>
                  <h2>Dados do pagamento</h2>
                  <p>Preencha os dados abaixo para prosseguir</p>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="form-item">
                        <label for="payment_method">Forma de Pagamento</label>
                        <select name="" id="payment_method" class="input-default" v-model="paymentData.payment_method">
                          <option value="" disabled>Selecione</option>
                          <option value="PIX">PIX</option>
                          <option value="CREDIT_CARD_MP">Cartão de Crédito</option>
                          <!-- <option value="CREDIT_CARD_MP">Cartão de Crédito (Mercado Pago)</option> -->
                        </select>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="paymentData.payment_method == 'PIX'">
                    <v-col cols="12" md="12">
                      <div class="form-item">
                        <label for="">Nome Completo</label>
                        <input type="text" class="input-default" placeholder="Nome Completo" v-model="paymentData.name">
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="form-item">
                        <label for="cpf">CPF</label>
                        <input type="text" id="cpf" class="input-default" v-mask="['###.###.###-##']"
                          placeholder="000.000.000-00" v-model="paymentData.cpf">
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="form-item">
                        <label for="">E-mail</label>
                        <input type="email" class="input-default" placeholder="E-mail" v-model="paymentData.email">
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="form-item">
                        <label for="">Telefone</label>
                        <input type="text" class="input-default" placeholder="E-mail" v-model="paymentData.phone"
                          v-mask="['(##) #####-####']">
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="form-item">
                        <label for="cep">CEP</label>
                        <input type="text" class="input-default" id="cep" placeholder="00000-000" @keyup="searchCep()"
                          v-model="paymentData.zipcode" v-mask="['#####-###']">
                      </div>
                    </v-col>
                    <v-col cols="12" md="10">
                      <div class="form-item">
                        <label for="street">Rua</label>
                        <input type="text" class="input-default" ref="street" id="street" v-model="paymentData.street"
                          :readonly="!cepResult">
                      </div>
                    </v-col>
                    <v-col cols="12" md="2">
                      <div class="form-item">
                        <label for="number">N°</label>
                        <input type="text" class="input-default" ref="number" id="number" placeholder="N°"
                          v-model="paymentData.number" :readonly="!cepResult">
                      </div>
                    </v-col>
                    <v-col cols="12" md="5">
                      <div class="form-item">
                        <label for="neighborhood">Bairro</label>
                        <input type="text" class="input-default" ref="neighborhood" id="neighborhood"
                          v-model="paymentData.neighborhood" :readonly="!cepResult">
                      </div>
                    </v-col>
                    <v-col cols="12" md="5">
                      <div class="form-item">
                        <label for="city">Cidade</label>
                        <input type="text" class="input-default" ref="city" id="city" v-model="paymentData.city"
                          :readonly="!cepResult">
                      </div>
                    </v-col>
                    <v-col cols="12" md="2">
                      <div class="form-item">
                        <label for="state">UF</label>
                        <input type="text" class="input-default" ref="state" id="state" v-model="paymentData.state"
                          :readonly="!cepResult">
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="paymentData.payment_method == 'CREDIT_CARD'">
                    <v-col>
                      <div class="visa-card">
                        <div class="logoContainer">
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23"
                            viewBox="0 0 48 48" class="svgLogo">
                            <path fill="#ff9800" d="M32 10A14 14 0 1 0 32 38A14 14 0 1 0 32 10Z"></path>
                            <path fill="#d50000" d="M16 10A14 14 0 1 0 16 38A14 14 0 1 0 16 10Z"></path>
                            <path fill="#ff3d00"
                              d="M18,24c0,4.755,2.376,8.95,6,11.48c3.624-2.53,6-6.725,6-11.48s-2.376-8.95-6-11.48 C20.376,15.05,18,19.245,18,24z">
                            </path>
                          </svg>
                        </div>
                        <div class="number-container">
                          <label class="input-label" for="cardNumber">NÚMERO DO CARTÃO</label>
                          <input class="inputstyle" id="cardNumber" placeholder="XXXX XXXX XXXX XXXX" name="cardNumber"
                            type="text" v-model="paymentData.card_number" readonly />
                        </div>

                        <div class="name-date-cvv-container">
                          <div class="name-wrapper">
                            <label class="input-label" for="holderName">NOME DO TITULAR</label>
                            <input class="inputstyle" id="holderName" placeholder="NAME" type="text" readonly
                              v-model="paymentData.holder" />
                          </div>

                          <div class="expiry-wrapper">
                            <label class="input-label" for="expiry">VALIDADE</label>
                            <input class="inputstyle" id="expiry" placeholder="MM/YY" type="text"
                              v-model="paymentData.expiration_date" readonly />
                          </div>
                          <div class="cvv-wrapper">
                            <label class="input-label" for="cvv">CVV</label>
                            <input class="inputstyle" placeholder="***" maxlength="3" id="cvv" v-model="paymentData.cvv"
                              readonly />
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="form-item">
                        <label for="holder">Nome do Titular</label>
                        <input type="text" class="input-default" ref="holder" id="holder" v-model="paymentData.holder">
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="form-item">
                        <label for="cpf">CPF do Titular</label>
                        <input type="text" id="cpf" class="input-default" v-mask="['###.###.###-##']"
                          placeholder="000.000.000-00" v-model="paymentData.cpf_cc">
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="form-item">
                        <label for="birth">Data de Nascimento do Titular</label>
                        <input type="text" id="birth" class="input-default" v-mask="['##/##/####']"
                          placeholder="00/00/0000" v-model="paymentData.birth">
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="form-item">
                        <label for="card_number">Número do Cartão</label>
                        <input type="text" class="input-default" @keyup="getInstallments"
                          :readonly="gettingInstallments" ref="card_number" id="card_number"
                          placeholder="0000 0000 0000 0000" v-mask="['#### #### #### ####']"
                          v-model="paymentData.card_number">
                      </div>
                    </v-col>

                    <v-col cols="12" md="12">
                      <div class="form-item">
                        <label for="installments">Parcelas</label>
                        <select class="input-default" name="" id="form-checkout__issuer"
                          v-model="paymentData.installments">
                          <option value="" disabled v-if="!gettingInstallments">Insira o número do cartão</option>
                          <option value="" disabled v-if="gettingInstallments">Aguarde...</option>
                          <option v-for="i in installments" :key="i.installment" :value="i.installment">{{ i.installment
                            }}x de {{ (i.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                          </option>
                        </select>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="form-item">
                        <label for="expiration_date">Data de Expiração</label>
                        <input type="text" class="input-default" ref="expiration_date" id="expiration_date"
                          placeholder="MM/AA" v-mask="['##/##']" v-model="paymentData.expiration_date">
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="form-item">
                        <label for="cvv">CVV</label>
                        <input type="text" class="input-default" ref="cvv" id="cvv" v-model="paymentData.cvv">
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="paymentData.payment_method == 'CREDIT_CARD_MP'">

                    <v-col cols="12" id="cardPaymentBrick_container">

                    </v-col>
                  </v-row>
                  <v-row v-if="paymentData.payment_method != 'CREDIT_CARD_MP'">
                    <v-col cols="12">
                      <button class="button-default" v-if="loading">
                        Aguarde...
                      </button>
                      <button class="button-default" @click="subscribe()" v-if="!loading">
                        Assinar
                      </button>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <v-col cols="12" v-if="planData != null">
            <h2>Registro de Pagamentos</h2>
          </v-col>
          <v-col v-if="planData != null">
            <v-container>
              <v-row>
                <v-col cols="12" md="2">
                  <button class="button-default" @click="openRenewModal()">Renovar / Alterar Plano</button>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-if="userData.paymentProvider == 'efi---'">
              <v-row>

                <v-col cols="12">
                  <div class="card-details">
                    <v-container>
                      <v-row>
                        <v-col>
                          <h2>Detalhes do Plano</h2>
                          <h3 v-if="planData.items[0].name == 'BG - MENSAL'">Plano Mensal</h3>
                          <h3 v-if="planData.items[0].name == 'BG - TRIMESTRAL'">Plano Trimestral</h3>
                          <h3 v-if="planData.items[0].name == 'BG - ANUAL'">Plano Anual</h3>
                        </v-col>
                        <v-col>
                          <h2>Próximo Vencimento</h2>
                          <h3>{{ new Date(userData.nextDueDate).toLocaleDateString() }}</h3>
                        </v-col>
                        <v-col>
                          <h2>Valor</h2>
                          <h3>{{ (planData.total / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }}
                          </h3>
                        </v-col>
                        <v-col v-if="planData.payment.credit_card">
                          <h2>Pago em</h2>
                          <h3>{{ planData.payment.credit_card.installments }}x {{
                            (planData.payment.credit_card.installment_value / 100).toLocaleString('pt-BR', {
                              style:
                                'currency', currency: 'BRL'
                            }) }}</h3>
                        </v-col>
                        <v-col>
                          <h2>Status</h2>
                          <h3 v-if="planData.status == 'waiting'" style="color: #00AF7D">Aguardando</h3>
                          <h3 v-if="planData.status == 'paid'" style="color: #00ff00">Pago</h3>
                          <h3 v-if="planData.status == 'settled'" style="color: #00ff00">Pago</h3>
                          <button class="button-default"
                            v-if="planData.status == 'waiting' && !planData.payment.credit_card"
                            @click="openPayment(planData)">
                            Pagar
                          </button>

                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <div class="card-pays">
                    <v-simple-table height="300px" dark color="#000">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Data
                            </th>
                            <th class="text-left">
                              Descrição
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="i in planData.history" :key="i.created_at">
                            <td>{{ new Date(i.created_at).toLocaleString() }}</td>
                            <td>{{ i.message }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>

                </v-col>
              </v-row>
            </v-container>

            <v-container v-if="userData.paymentProvider == 'mercadopago' || userData.paymentProvider == 'efi'">
              <v-row>
                <v-col cols="12">
                  <div class="card-details">
                    <v-container>
                      <v-row>
                        <!-- <v-col>
                          <h2>Detalhes do Plano</h2>
                          <h3 v-if="planData.items[0].name == 'BG - MENSAL'">Plano Mensal</h3>
                          <h3 v-if="planData.items[0].name == 'BG - TRIMESTRAL'">Plano Trimestral</h3>
                          <h3 v-if="planData.items[0].name == 'BG - ANUAL'">Plano Anual</h3>
                        </v-col> -->
                        <v-col>
                          <h2>Próximo Vencimento</h2>
                          <h3>{{ planData.nextDueDate != null ? new Date(planData.nextDueDate).toLocaleDateString() :
                            'Calculando...' }}</h3>
                        </v-col>
                        <v-col>
                          <h2>Status</h2>
                          <h3 v-if="planData.chargeStatus == 'pending'" style="color: #00AF7D">Aguardando</h3>
                          <h3 v-if="planData.chargeStatus == 'approved'" style="color: #00ff00">Pago</h3>
                          <h3 v-if="planData.chargeStatus == 'in_process'" style="color: #00AF7D">Aguardando Aprovação
                          </h3>
                          <h3 v-if="planData.chargeStatus == 'authorized'" style="color: #00AF7D">Aguardando Aprovação
                          </h3>

                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <!-- <div class="card-pays">
                    <v-simple-table height="300px" dark color="#000" >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Data
                            </th>
                            <th class="text-left">
                              Descrição
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="i in planData.history"
                            :key="i.created_at"
                          >
                            <td>{{ new Date(i.created_at).toLocaleString() }}</td>
                            <td>{{ i.message}}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div> -->

                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="mPay" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5">
          Pagamento
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <img style="width: 100%;"
                  :src="chargeSelected ? chargeSelected.payment.banking_billet.pix.qrcode_image : ''" alt="">
              </v-col>
              <v-col>
                <h2>Valor: {{ ((chargeSelected ? chargeSelected.total : 0) / 100).toLocaleString('pt-BR', {
                  style:
                    'currency', currency: 'BRL'
                }) }}</h2>
                <h3>Vencimento: {{ chargeSelected ? new
                  Date(chargeSelected.payment.banking_billet.expire_at).toLocaleDateString() : '' }}</h3>
                <h3>Ou Copie: <input class="input-default" type="text"
                    :value="chargeSelected ? chargeSelected.payment.banking_billet.pix.qrcode : ''"></h3>
              </v-col>
              <v-col cols="12">
                <h3>Para pagar, abra o aplicativo do seu banco e escaneie o QRCode ou copie o código acima e aguarde.
                </h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="pink darken-1"
            text
            @click="mPay = false"
          >
            Pronto
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mRenew" persistent max-width="80%" style="z-index: 99999">
      <v-card>
        <v-card-title class="text-h5">
          Renovar / Alterar Plano
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col v-if="planSelected == null">
              <CardPlans :renew="true" @selectPlan="selectPlanRenew" />
            </v-col>
            <v-col v-if="planSelected != null">
              <v-container>
                <v-row>
                  <v-col cols="12" md="4">
                    <h2>Resumo do pedido</h2>
                    <p>Confira abaixo os valores cobrados</p>

                    <div class="card-resume">
                      <h2>{{ planSelected.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h2>
                      <h3>{{ planSelected.type }}</h3>
                    </div>
                  </v-col>
                  <v-col>
                    <h2>Dados do pagamento</h2>
                    <p>Preencha os dados abaixo para prosseguir</p>
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="form-item">
                          <label for="payment_method">Forma de Pagamento</label>
                          <select name="" id="payment_method" class="input-default"
                            v-model="paymentData.payment_method">
                            <option value="" disabled>Selecione</option>
                            <option value="PIX">PIX</option>
                            <option value="CREDIT_CARD_MP">Cartão de Crédito</option>
                            <!-- <option value="CREDIT_CARD_MP">Cartão de Crédito (Mercado Pago)</option> -->
                          </select>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row v-if="paymentData.payment_method == 'PIX'">
                      <v-col cols="12" md="12">
                        <div class="form-item">
                          <label for="">Nome Completo</label>
                          <input type="text" class="input-default" placeholder="Nome Completo"
                            v-model="paymentData.name">
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div class="form-item">
                          <label for="cpf">CPF</label>
                          <input type="text" id="cpf" class="input-default" v-mask="['###.###.###-##']"
                            placeholder="000.000.000-00" v-model="paymentData.cpf">
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="form-item">
                          <label for="">E-mail</label>
                          <input type="email" class="input-default" placeholder="E-mail" v-model="paymentData.email">
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="form-item">
                          <label for="">Telefone</label>
                          <input type="text" class="input-default" placeholder="E-mail" v-model="paymentData.phone"
                            v-mask="['(##) #####-####']">
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div class="form-item">
                          <label for="cep">CEP</label>
                          <input type="text" class="input-default" id="cep" placeholder="00000-000" @keyup="searchCep()"
                            v-model="paymentData.zipcode" v-mask="['#####-###']">
                        </div>
                      </v-col>
                      <v-col cols="12" md="10">
                        <div class="form-item">
                          <label for="street">Rua</label>
                          <input type="text" class="input-default" ref="street" id="street" v-model="paymentData.street"
                            :readonly="!cepResult">
                        </div>
                      </v-col>
                      <v-col cols="12" md="2">
                        <div class="form-item">
                          <label for="number">N°</label>
                          <input type="text" class="input-default" ref="number" id="number" placeholder="N°"
                            v-model="paymentData.number" :readonly="!cepResult">
                        </div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="form-item">
                          <label for="neighborhood">Bairro</label>
                          <input type="text" class="input-default" ref="neighborhood" id="neighborhood"
                            v-model="paymentData.neighborhood" :readonly="!cepResult">
                        </div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="form-item">
                          <label for="city">Cidade</label>
                          <input type="text" class="input-default" ref="city" id="city" v-model="paymentData.city"
                            :readonly="!cepResult">
                        </div>
                      </v-col>
                      <v-col cols="12" md="2">
                        <div class="form-item">
                          <label for="state">UF</label>
                          <input type="text" class="input-default" ref="state" id="state" v-model="paymentData.state"
                            :readonly="!cepResult">
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="paymentData.payment_method == 'CREDIT_CARD'">
                      <v-col>
                        <div class="visa-card">
                          <div class="logoContainer">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23"
                              viewBox="0 0 48 48" class="svgLogo">
                              <path fill="#ff9800" d="M32 10A14 14 0 1 0 32 38A14 14 0 1 0 32 10Z"></path>
                              <path fill="#d50000" d="M16 10A14 14 0 1 0 16 38A14 14 0 1 0 16 10Z"></path>
                              <path fill="#ff3d00"
                                d="M18,24c0,4.755,2.376,8.95,6,11.48c3.624-2.53,6-6.725,6-11.48s-2.376-8.95-6-11.48 C20.376,15.05,18,19.245,18,24z">
                              </path>
                            </svg>
                          </div>
                          <div class="number-container">
                            <label class="input-label" for="cardNumber">NÚMERO DO CARTÃO</label>
                            <input class="inputstyle" id="cardNumber" placeholder="XXXX XXXX XXXX XXXX"
                              name="cardNumber" type="text" v-model="paymentData.card_number" readonly />
                          </div>

                          <div class="name-date-cvv-container">
                            <div class="name-wrapper">
                              <label class="input-label" for="holderName">NOME DO TITULAR</label>
                              <input class="inputstyle" id="holderName" placeholder="NAME" type="text" readonly
                                v-model="paymentData.holder" />
                            </div>

                            <div class="expiry-wrapper">
                              <label class="input-label" for="expiry">VALIDADE</label>
                              <input class="inputstyle" id="expiry" placeholder="MM/YY" type="text"
                                v-model="paymentData.expiration_date" readonly />
                            </div>
                            <div class="cvv-wrapper">
                              <label class="input-label" for="cvv">CVV</label>
                              <input class="inputstyle" placeholder="***" maxlength="3" id="cvv"
                                v-model="paymentData.cvv" readonly />
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div class="form-item">
                          <label for="holder">Nome do Titular</label>
                          <input type="text" class="input-default" ref="holder" id="holder"
                            v-model="paymentData.holder">
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="form-item">
                          <label for="cpf">CPF do Titular</label>
                          <input type="text" id="cpf" class="input-default" v-mask="['###.###.###-##']"
                            placeholder="000.000.000-00" v-model="paymentData.cpf_cc">
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="form-item">
                          <label for="birth">Data de Nascimento do Titular</label>
                          <input type="text" id="birth" class="input-default" v-mask="['##/##/####']"
                            placeholder="00/00/0000" v-model="paymentData.birth">
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div class="form-item">
                          <label for="card_number">Número do Cartão</label>
                          <input type="text" class="input-default" @keyup="getInstallments"
                            :readonly="gettingInstallments" ref="card_number" id="card_number"
                            placeholder="0000 0000 0000 0000" v-mask="['#### #### #### ####']"
                            v-model="paymentData.card_number">
                        </div>
                      </v-col>

                      <v-col cols="12" md="12">
                        <div class="form-item">
                          <label for="installments">Parcelas</label>
                          <select class="input-default" name="" id="form-checkout__issuer"
                            v-model="paymentData.installments">
                            <option value="" disabled v-if="!gettingInstallments">Insira o número do cartão</option>
                            <option value="" disabled v-if="gettingInstallments">Aguarde...</option>
                            <option v-for="i in installments" :key="i.installment" :value="i.installment">{{
                              i.installment
                              }}x de {{ (i.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                              }}
                            </option>
                          </select>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="form-item">
                          <label for="expiration_date">Data de Expiração</label>
                          <input type="text" class="input-default" ref="expiration_date" id="expiration_date"
                            placeholder="MM/AA" v-mask="['##/##']" v-model="paymentData.expiration_date">
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="form-item">
                          <label for="cvv">CVV</label>
                          <input type="text" class="input-default" ref="cvv" id="cvv" v-model="paymentData.cvv">
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="paymentData.payment_method == 'CREDIT_CARD_MP'">

                      <v-col cols="12" id="cardPaymentBrick_container">

                      </v-col>
                    </v-row>
                    <v-row v-if="paymentData.payment_method != 'CREDIT_CARD_MP'">
                      <v-col cols="12">
                        <button class="button-default" v-if="loading">
                          Aguarde...
                        </button>
                        <button class="button-default" @click="subscribe()" v-if="!loading">
                          Assinar
                        </button>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00AF7D" text @click="mRenew = false; planSelected = null">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading v-if="loadingPage" />

    <!-- <button class="button-subscribe" style="width: 100px" @click="selectPlan('Trimestral', 10)">
      Teste 10
    </button> -->

    <!-- <button class="button-subscribe" style="width: 100px" @click="selectPlan({ type: 'Mensal', price: 3.5 })">
      Teste 5
    </button> -->
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask'
import axios from 'axios'
import config from '@/store/config'
import Loading from '@/components/Loading.vue'
import CardPlans from '../../components/Plans/CardPlans.vue'

import { loadMercadoPago } from "@mercadopago/sdk-js";

await loadMercadoPago();
var mp
if (process.env.VUE_APP_SANDBOX == 'true') {
  console.log('sandbox')
  mp = new window.MercadoPago("APP_USR-ea6c01b5-5a79-4f50-8404-f99bbe45448f");
} else {
  console.log('produção')
  mp = new window.MercadoPago("APP_USR-ea6c01b5-5a79-4f50-8404-f99bbe45448f");
}

const api = axios.create({
  baseURL: config.baseURL
})

function validateCpf(cpf) {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9, 10].forEach(function (j) {
    var soma = 0, r;
    cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
      soma += parseInt(e) * ((j + 2) - (i + 1));
    });
    r = soma % 11;
    r = (r < 2) ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
  });
  return result;
}



export default {
  name: 'PlansPage',
  metaInfo() {
    return {
      title: `Planos - ${config.name} - Beeasy - ${this.userData.fullName}`
    }
  },
  directives: {
    mask
  },
  components: {
    Loading,
    CardPlans
  },
  data() {
    return {
      paymentData: {
        cpf: '',
        payment_method: '',
        name: '',
        zipcode: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        email: '',
        phone: '',
        holder: '',
        cpf_cc: '',
        birth: '',
        card_number: '',
        expiration_date: '',
        cvv: '',
        installments: ''

      },
      gettingInstallments: false,
      installments: [],
      loading: false,
      loadingPage: false,
      mPay: false,
      mRenew: false,
      chargeSelected: null,
      planData: null,
      cepResult: false,
      planSelected: null,
      userData: {},
      storeData: {},

    }
  },
  watch: {
    'paymentData.payment_method': async function () {
      if (this.paymentData.payment_method == 'CREDIT_CARD_MP') {
        setTimeout(() => {
          this.initMP()
        }, 500);
      }
    }
  },
  async mounted() {
    await this.init()
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de planos.`})
  },
  async created() {
    //await this.init()

  },
  methods: {
    async init() {
      this.loadingPage = true
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      if (this.userData.chargeId != null) {
        console.log(this.userData)
        if (this.userData.paymentProvider == 'mercadopago' || this.userData.paymentProvider == 'efi') {
          console.log('mercado pago - >Exe')
          let response = await api.get(`/me`, await this.$store.getters.getConfig)
          this.planData = response.data.user
        } else {
          let planData = await api.get(`/plan`, await this.$store.getters.getConfig)
          this.planData = planData.data
        }

      }
      this.paymentData = {
        ...this.paymentData,
        email: this.userData.email,
        phone: this.userData.whatsapp
      }
      this.loadingPage = false
    },
    async initMP() {
      let app = this
      const bricksBuilder = mp.bricks();
      let maxInstallments = 12

      if (this.planSelected.type == 'Mensal') {
        maxInstallments = 1
      }

      if (this.planSelected.type == 'Trimestral') {
        maxInstallments = 3
      }

      if (this.planSelected.type == 'Anual') {
        maxInstallments = 12
      }

      const renderCardPaymentBrick = async (bricksBuilder) => {
        const settings = {
          initialization: {
            amount: parseFloat(app.planSelected.price).toFixed(2), // valor total a ser pago
            payer: {
              email: "",
            },
          },
          customization: {
            visual: {
              style: {
                customVariables: {
                  theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
                }
              }
            },
            paymentMethods: {
              types: {
                excluded: ['debit_card']
              },
              maxInstallments,
            }
          },
          callbacks: {
            onReady: () => {
              // callback chamado quando o Brick estiver pronto
            },
            onSubmit: (cardFormData) => {
              app.subscribe(cardFormData)
            },
            onError: (error) => {
              // callback chamado para todos os casos de erro do Brick
            },
          },
        };
        window.cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
      };
      renderCardPaymentBrick(bricksBuilder);

    },

    async selectPlan(data) {
      let { type, price } = data
      console.log(process.env.VUE_APP_SANDBOX)
      if (type == 'Mensal') {
        this.planSelected = {
          type,
          plan_id: process.env.VUE_APP_SANDBOX == 'true' ? 12003 : 113251,
          price: price,
          renew: false
        }
        this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] selecionou o plano mensal.`})
      }
      if (type == 'Trimestral') {
        this.planSelected = {
          type,
          plan_id: process.env.VUE_APP_SANDBOX == 'true' ? 12004 : 113252,
          price: price,
          renew: false
        }

        this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] selecionou o plano trimestral.`})
      }

      if (type == 'Anual') {
        this.planSelected = {
          type,
          plan_id: process.env.VUE_APP_SANDBOX == 'true' ? 12005 : 113253,
          price: price,
          renew: false
        }

        this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] selecionou o plano anual.`})
      }
    },

    async selectPlanRenew(data) {
      let { type, price } = data
      console.log(process.env.VUE_APP_SANDBOX)
      if (type == 'Mensal') {
        this.planSelected = {
          type,
          plan_id: process.env.VUE_APP_SANDBOX == 'true' ? 12003 : 113251,
          price: price,
          renew: true
        }

        this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] selecionou o plano mensal.`})
      }
      if (type == 'Trimestral') {
        this.planSelected = {
          type,
          plan_id: process.env.VUE_APP_SANDBOX == 'true' ? 12004 : 113252,
          price: price,
          renew: true
        }

        this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] selecionou o plano trimestral.`})
      }

      if (type == 'Anual') {
        this.planSelected = {
          type,
          plan_id: process.env.VUE_APP_SANDBOX == 'true' ? 12005 : 113253,
          price: price,
          renew: true
        }

        this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] selecionou o plano anual.`})
      }
    },
    async findCep(cep) {
      try {
        let response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async searchCep() {
      if (this.paymentData.zipcode.length == 9) {
        let cep = this.paymentData.zipcode.replace('-', '')
        let result = await this.findCep(cep)
        if (result.erro) {
          this.$toast.warning('CEP não encontrado. Insira o endereço manualmente.')
          this.cepResult = true
          this.$nextTick(() => this.$refs.street.focus())
          this.paymentData.street = ''
          this.paymentData.city = ''
          this.paymentData.state = ''
          this.paymentData.number = ''
          this.paymentData.neighborhood = ''

        } else {
          this.cepResult = true
          this.$nextTick(() => this.$refs.number.focus())
          this.paymentData.street = result.logradouro
          this.paymentData.city = result.localidade
          this.paymentData.state = result.uf
          this.paymentData.neighborhood = result.bairro
        }
        this.loading = false
      }
    },
    async getInstallments() {
      console.log(this.paymentData.card_number.length)
      if (this.paymentData.card_number.length >= 19) {
        this.gettingInstallments = true
        let data = {
          amount: parseFloat(this.planSelected.price).toFixed(2),
          card_number: this.paymentData.card_number,
          type: this.planSelected.type
        }
        try {
          let response = await api.post('/installments', data, await this.$store.getters.getConfig)
          if (response.data.error) {
            this.$toast.error(response.data.message)
            this.gettingInstallments = false
            return
          }
          this.installments = response.data
          this.gettingInstallments = false

        } catch (error) {
          this.gettingInstallments = false
          console.log(error)
        }
      } else {
        this.gettingInstallments = false
      }
    },
    async subscribe(mpData) {
      this.loading = true
      if (this.paymentData.payment_method == '') {
        this.$toast.error('Selecione a forma de pagamento')
        this.loading = false
        return
      }

      if (this.paymentData.payment_method == 'PIX') {
        if (this.paymentData.name == '' || this.paymentData.cpf == '' || this.paymentData.email == '' || this.paymentData.phone == '' || this.paymentData.zipcode == '' || this.paymentData.street == '' || this.paymentData.number == '' || this.paymentData.neighborhood == '' || this.paymentData.city == '' || this.paymentData.state == '') {
          this.$toast.error('Preencha todos os campos')
          this.loading = false
          return
        }

        if (!validateCpf(this.paymentData.cpf)) {
          this.$toast.error('CPF inválido')
          this.loading = false
          return
        }
        let data = {
          plan_id: this.planSelected.plan_id,
          zipcode: this.paymentData.zipcode,
          user_id: this.userData.id,
          name: this.paymentData.name,
          store_id: this.storeData.id,
          cpf: this.paymentData.cpf,
          cpf_cc: this.paymentData.cpf_cc,
          email: this.paymentData.email,
          phone: this.paymentData.phone,
          zipcode: this.paymentData.zipcode,
          street: this.paymentData.street,
          number: this.paymentData.number,
          neighborhood: this.paymentData.neighborhood,
          birth: this.paymentData.birth.split('/').reverse().join('-'),
          city: this.paymentData.city,
          state: this.paymentData.state,
          payment_method: this.paymentData.payment_method,
          type: this.planSelected.type,
          price: parseFloat(this.planSelected.price).toFixed(2),
          payment_method: this.paymentData.payment_method,
          notification_url: `${config.baseURL}webhook`,
          installments: this.paymentData.installments,
          renew: this.planSelected.renew
        }

        try {
          let subscribe = await api.post('/subscribe', data, await this.$store.getters.getConfig)
          if (subscribe.data.error) {
            subscribe.data.error_description.message ? this.$toast.error(subscribe.data.error_description.message) : this.$toast.error(subscribe.data.error_description)
            this.loading = false
            return
          }
          this.$toast.success('Assinatura realizada com sucesso')
          this.$store.commit('insertLog',{ action: 'Assinatura',  description: `[${this.userData.fullName}] Assinou o plano ${this.planSelected.type}`})

          let response = await api.get(`/me`, await this.$store.getters.getConfig)
          localStorage.setItem('user', JSON.stringify(response.data.user))
          this.$store.commit('update_dados', response.data.user)

          if (this.planSelected.renew) {
            var plan = await api.get('/plan/tmp', await this.$store.getters.getConfig)
            this.planData = plan.data
            this.mRenew = false
            await this.openPayment(this.planData)
          } else {
            var plan = await api.get('/plan', await this.$store.getters.getConfig)
            this.planData = plan.data
          }




          this.loading = false
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$toast.error('Erro ao realizar assinatura')
        }
      }
      if (this.paymentData.payment_method == 'CREDIT_CARD_MP') {
        let data = {
          plan_id: this.planSelected.plan_id,
          user_id: this.userData.id,
          store_id: this.storeData.id,
          payment_method: this.paymentData.payment_method,
          type: this.planSelected.type,
          renew: this.planSelected.renew,
          mpData
        }

        try {
          let subscribe = await api.post('/subscribe', data, await this.$store.getters.getConfig)
          console.log(subscribe)
          if (subscribe.data.error) {
            this.$toast.error(subscribe.data.message)
            this.loading = false
            setTimeout(() => {
              location.reload()
            }, 2000);
            return
          }

          if (subscribe.data.success) {
            if (subscribe.data.status == 'approved') {
              this.$toast.success('Assinatura realizada com sucesso')
            }

            if (subscribe.data.status == 'in_process') {
              this.$toast.success('Assinatura realizada com sucesso. Aguarde a confirmação do pagamento')
            }
          }

          let response = await api.get(`/me`, await this.$store.getters.getConfig)
          localStorage.setItem('user', JSON.stringify(response.data.user))
          this.$store.commit('update_dados', response.data.user)

          location.reload()
          this.loading = false
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$toast.error('Erro ao realizar assinatura')
        }
      }
    },
    async getCharge(charge_id) {
      try {
        let response = await api.get(`/charge/${charge_id}`, await this.$store.getters.getConfig)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async openPayment(charge) {
      this.chargeSelected = charge
      this.mPay = true

      let interval = setInterval(async () => {
        let charge = await this.getCharge(this.chargeSelected.charge_id)
        console.log(charge.status)
        if (charge.status == 'paid' || charge.status == 'settled') {
          clearInterval(interval)
          this.mPay = false
          this.$toast.success('Pagamento realizado com sucesso')
          let plan = await api.get('/plan', await this.$store.getters.getConfig)
          this.planData = plan.data
        }
      }, 5000)
    },
    async openRenewModal() {
      this.mRenew = true
      this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] Abriu o modal de renovação de plano`})
    }

  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid rgba(64, 64, 73, .3);

}

.input-default[readonly] {
  background-color: #f2f2f2;
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.card-resume {
  background-color: #1c1a1d;
  color: #fff;
  border-radius: 20px;
  padding: 15px;
}

.form-item label {
  font-size: 1rem;
  font-weight: 500;
  color: #404049;
}


.card-pays {
  background-color: #1c1a1d;
  color: #fff;
  border-radius: 20px;
  padding: 15px;
}

.card-details {
  background-color: #00AF7D;
  color: #fff;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 20px;
}

/* CARTAO */
.visa-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 300px;
  height: 180px;
  background-image: radial-gradient(circle 897px at 9% 80.3%,
      rgba(55, 60, 245, 1) 0%,
      rgba(234, 161, 15, 0.9) 100.2%);
  border-radius: 10px;
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  gap: 15px;
}

.logoContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  padding: 18px;
}

.svgLogo {
  height: 40px;
  width: auto;
}

.inputstyle::placeholder {
  color: #ffffff;
}

.inputstyle {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  caret-color: red;
  font-size: 13px;
  height: 25px;
  letter-spacing: 1.5px;
}

.number-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

#cardNumber {
  width: 100%;
  height: 25px;
}

.name-date-cvv-container {
  width: 100%;
  height: 25px;
  display: flex;
  gap: 10px;
}

.name-wrapper {
  width: 60%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.expiry-wrapper,
.cvv-wrapper {
  width: 30%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.cvv-wrapper {
  width: 10%;
}

#expiry,
#cvv {
  width: 100%;
}

.input-label {
  font-size: 8px;
  letter-spacing: 1.5px;
  color: #e2e2e2;
  width: 100%;
}

/* #form-checkout {
      display: flex;
      flex-direction: column;
      max-width: 600px;
    }

    .containermp {
      height: 18px;
      display: inline-block;
      border: 1px solid rgb(118, 118, 118);
      border-radius: 2px;
      padding: 1px 2px;
    } */
</style>