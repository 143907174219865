<template>
  <v-col class="text-start">
    <h2>Configure seu domínio</h2>
    <p>Verifique abaixo a disponibilidade do seu domínio</p>
    <v-row>
      <v-col cols="12">
        <div class="form-item domain-input">
          <label for="domain">Domínio</label>
          <div class="domain-field">
            <input type="text" id="domain" class="input-default domain-name" placeholder="Digite seu domínio"
              v-model="domainName" @input="updateDomainData('domainName', $event.target.value)" />
            <select class="input-default domain-extension" v-model="domainExtension"
              @change="updateDomainData('domainExtension', $event.target.value)">
              <option value=".com">.com</option>
              <option value=".com.br">.com.br</option>
              <option value=".shop">.shop</option>
            </select>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <button class="button-default" v-if="loading">Aguarde...</button>
        <button class="button-default" @click="subscribe" v-if="!loading">Contratar</button>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "DomainConfiguration",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    domainName: {
      type: String,
      required: true
    },
    domainExtension: {
      type: String,
      required: true
    }
  },
  methods: {
    updateDomainData(key, value) {
      this.$emit('update-domain-data', key, value);
    },
    subscribe() {
      this.$emit('subscribe');
    }
  }
};
</script>

<style scoped>
.form-item {
  margin-bottom: 1rem;
}

.domain-input {
  display: flex;
  flex-direction: column;
}

.domain-field {
  display: flex;
  align-items: center;
}

.domain-name {
  flex: 1;
  margin-right: 10px;
}

.domain-extension {
  width: auto;
}

.input-default {
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}
</style>
