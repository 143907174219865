<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2>Relatório de Pagamentos de Assinaturas</h2>
          </v-col>
          <v-col>
            <label for="">Data Inicial</label>
            <input type="date" v-model="start" class="input-default" placeholder="Inicio">
          </v-col>
          <v-col>
            <label for="">Data Final</label>
            <input type="date" v-model="end" class="input-default" placeholder="Fim">
          </v-col>
          <v-col>
            <label for="" style="opacity: 0">-</label>
            <button class="button-default" @click="getOrders">Filtrar</button>
          </v-col>
          
          <v-col cols=12>
            <v-data-table :headers="headers" :items="orders" :items-per-page="100000" class="elevation-1">

              <template v-slot:[`item.value`]="{ item }">
                <strong>{{item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</strong>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>Totalizadores</h2>
      </v-col>
      <v-col>
        <div class="card work">
          <div class="img-section">
            Total período
          </div>
          <div class="card-desc">
          <div class="card-header">
          <div class="card-title"></div>
          <div class="card-menu">
          <div class="dot2"></div>
          <div class="dot2"></div>
          <div class="dot2"></div>
          </div>
          </div>
          <div class="card-time">{{totalPeriod.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</div>
          </div>
        </div>
      </v-col>
    </v-row>
    <Loading v-if="loadingPage" />
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})


export default {
  name: 'AdmOrdersPage',
  metaInfo() {
    return {
      title: `Relatório de Usuários - Bruno Graminha - Beeasy - ${this.userData.fullName}`
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      headers: [
        {
          text: 'Código Pagamento',
          align: 'left',
          sortable: false,
          value: 'codigo_pagamento',
        },
        { text: 'Usuário', value: 'usuario', align: 'left', },
        { text: 'Email', value: 'email', align: 'left', },
        { text: 'Whatsapp', value: 'whatsapp', align: 'left', },
        { text: 'Valor', value: 'value', align: 'left', },
        { text: 'Pago com', value: 'pago_com', align: 'left', },
        { text: 'Plano', value: 'plano', align: 'left', },
        { text: 'Data', value: 'data', align: 'left', },
      ],
      orders: [],
      userData: {},
      storeData: {},
      start: '',
      end: '',
      loadingPage: false,
      totalPeriod: 0,
      totalSystemComissions: 0
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.start = firstDay.toISOString().split('T')[0]
      this.end = lastDay.toISOString().split('T')[0]
      await this.getOrders()
      
    },
    async getOrders() {
      try {
        this.orders = []
        this.loadingPage = true
        const { data } = await api.get(`/report/payments/${this.start}/${this.end}`, await this.$store.getters.getConfig)
        this.orders = data
        this.loadingPage = false

  

        this.totalSystemComissions = this.orders.reduce((acc, order) => {
          return acc + order.comissao_sistema
        }, 0)

        this.totalPeriod = this.orders.reduce((acc, order) => {
          return acc + order.value
        }, 0)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.column-pos {
  text-align: center;
  color: rgb(55 65 81);
  ;
  padding: .5rem;
}

.column-title {
  font-size: 1rem;
  font-weight: 400;
}

.column-subtitle {
  font-size: .8rem;
  font-weight: 400;
}
</style>
