<template>
  <v-container class="container-page">
    <v-row>
      <v-col cols="12" class="text-left">
        <h2>Registro de Domínio</h2>
      </v-col>
    </v-row>
    <v-row class="plan-details">
      <v-col cols="12" md="3">
        <div class="detail-item">
          <h3>Domínio</h3>
          <p>{{ storeData.slug }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import axios from 'axios';
import config from '@/store/config';
import Loading from '@/components/Loading.vue';
import { mapState } from 'vuex';

const api = axios.create({ baseURL: config.baseURL });

export default {
  name: "PaymentRecord",
  components: { Loading },
  data() {
    return {
      loading: false,
      loadingPage: false,
    };
  },
  computed: {
    ...mapState(['storeData'])
  }
};
</script>


<style scoped>
.renew-button {
  margin-bottom: 20px;
}

.plan-details {
  background-color: #00AF7D;
  color: #fff;
  border-radius: 20px;
  padding: 20px;
}

.detail-item h3 {
  font-size: 1.2rem;
  font-weight: 700;
}

.detail-item p {
  font-size: 1rem;
  font-weight: 500;
}

.status-paid {
  color: #00ff00;
}
</style>
