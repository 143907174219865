<template>
  <v-container class="container-page">
    <h6>{{ token }}</h6>
    <!-- <iframe id="iframe-desktop" v-if="!$store.getters.isMobile" src="https://tawk.to/chat/65ce2e909131ed19d96d238f/1hudu7vas" frameborder="0"></iframe>
    <iframe id="iframe-mobile" v-if="$store.getters.isMobile" src="https://tawk.to/chat/65ce2e909131ed19d96d238f/1hudu7vas" frameborder="0"></iframe> -->
  </v-container>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})

export default {
  name: 'HelpPage',
  metaInfo() {
    return {
      title: `Ajuda - ${config.name} - Beeasy - ${this.userData.fullName} - ${this.token}`
    }
  },
  components: {
  },
  data() {
    return {
      step: 3,
      loading: false,
      productsNews: null,
      token: localStorage.getItem('token'),
      userData: {},
      storeData: {},
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);

}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

#iframe-desktop {
  display: block;
  margin: 0 auto;
  width: 50%;
  height: 70vh;
}

#iframe-mobile {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 70vh;
}
</style>