import Vue from "vue"
import VueRouter from "vue-router"

// LAYOUTS
import LoginLayout from "../layouts/LoginLayout.vue"
import DashboardLayout from "../layouts/DashboardLayout.vue"

// VIEWS
import LoginPage from "../views/LoginPage.vue"
import RegisterPage from "../views/RegisterPage.vue"
import ForgotPage from "../views/ForgotPage.vue"

// DASHBOARD VIEWS
import HomePage from "../views/dashboard/HomePage.vue"
import StorePage from "../views/dashboard/StorePage.vue"
import ProductsPage from "../views/dashboard/ProductsPage.vue"
import WalletPage from "../views/dashboard/WalletPage.vue"
import PlansPage from "@/views/dashboard/PlansPage.vue"
import OrdersPage from "@/views/dashboard/OrdersPage.vue"
import HelpPage from "@/views/dashboard/HelpPage.vue"
import DomainPage from "../views/dashboard/DomainPage.vue"
import MarketingPage from "../views/dashboard/MarketingPage.vue"

import AdmOrdersPage from "@/views/dashboard/adm/AdmOrdersPage.vue"
import AdmWithdrawsPage from "@/views/dashboard/adm/AdmWithdrawsPage.vue"
import AdmLogsPage from "@/views/dashboard/adm/AdmLogsPage.vue"
import AdmPaymentsPage from "@/views/dashboard/adm/AdmPaymentsPage.vue"
import AdmUsersPager from "@/views/dashboard/adm/AdmUsersPager.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: LoginLayout,
    children: [
      {
        path: "/",
        component: LoginPage
      },
      {
        path: "/login",
        component: LoginPage
      },
      {
        path: "/register",
        component: RegisterPage
      },
      {
        path: "/forgot-password/:token",
        component: ForgotPage
      }
    ]
  },
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "/home",
        component: HomePage
      },
      {
        path: "/products",
        component: ProductsPage
      },
      {
        path: "/store",
        component: StorePage
      },
      {
        path: "/plans",
        component: PlansPage
      },
      {
        path: "/orders",
        component: OrdersPage
      },
      {
        path: "/help",
        component: HelpPage
      },
      {
        path: "/wallet",
        component: WalletPage
      },
      {
        path: "/domain",
        component: DomainPage
      },
      {
        path: "/marketing",
        component: MarketingPage
      },

      // ADM

      {
        path: "/adm/orders",
        component: AdmOrdersPage
      },
      {
        path: "/adm/withdraws",
        component: AdmWithdrawsPage
      },
      {
        path: "/adm/logs",
        component: AdmLogsPage
      },
      {
        path: "/adm/users",
        component: AdmUsersPager
      },
      {
        path: "/adm/payments",
        component: AdmPaymentsPage
      }
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
