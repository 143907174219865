<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12">
            <h2>Configurar Marketing</h2>
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="12">
                      <h3>
                        Pixel do Facebook
                        <v-icon @click="linkDialog = true" small>mdi-help-circle</v-icon>
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6">
                      <label for="pixelFbId">Facebook Pixel ID</label>
                      <input v-model="pixelFbId" type="text" class="input-default" id="pixelFbId"
                        placeholder="Inserir ID">
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <button :class="{ 'button-default': true, 'disabled': !verified }" @click="save"
                        :disabled="!verified" v-if="!loading">Salvar</button>
                      <button :class="{ 'button-default': true, 'disabled': true }" v-if="loading">Aguarde...</button>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Dialogo de Ajuda -->
    <v-dialog v-model="linkDialog" max-width="700px" style="z-index: 999999" content-class="dialog-content">
      <v-card>
        <v-card-title class="headline">Ajuda - Pixel do Facebook</v-card-title>
        <v-card-text>
          <div>
            <h2>Integre seu Pixel do Facebook</h2>
            <p>Em um dos posts mais simples da Central de Ajuda vamos te ensinar a integrar seu Pixel do Facebook a sua
              loja na Beeasy.</p>
            <p>Quem já tem algum conhecimento sobre Marketing Digital ou já tentou realizar uma integração manual do
              Pixel do Facebook em alguma loja vai entender minha alegria em dizer que com a gente você está a um CTRL+V
              de ver tudo funcionando. Sério, tá muuuuito simples :)</p>

            <h3>Criando seu Pixel</h3>
            <p>Criar um novo Pixel no Facebook é muito simples e você consegue fazer isso em 30 segundos. Basta acessar
              o Events Manager (Gerenciador de Eventos) do Facebook através do seu Ads Manager.</p>
            <p>Na seção Fontes de Dados desta página, é só clicar em "Começar" para criar seu Pixel. Depois disso, uma
              janela se abrirá onde você pode definir o nome do seu Pixel.</p>
            <p>Voilá! Ao clicar em "Continuar", seu Pixel já vai estar devidamente criado.</p>

            <h3>Integrando o Pixel a sua loja</h3>
            <p>Agora você garantir que as páginas da sua loja enviem os dados corretos pro seu Pixel. Pode ficar
              tranquilo que já cuidamos de todo o trabalho pesado pra você e a integração é super fácil.</p>
            <p>Primeiro, você precisará do seu Pixel ID, que é o código identificador do seu Pixel. Você tem acesso a
              esse código no Event Manager do Facebook. Lá você verá todos os Pixels atrelados a sua conta e pode copiar
              o código do Pixel que será usado na sua loja da Beeasy.</p>
            <p>Com seu ID em mãos, agora é hora de acessar seu Dashboard na Beeasy e acessar a página "Marketing". Lá você vai encontrar um campo "Facebook Pixel ID", e basta que você cole o número do seu Pixel
              nesse campo e clique em "Salvar".</p>
            <p>E tá pronto! É tão simples quanto isso fazer sua integração na Beeasy :)</p>
            <p>O legal é que você pode conferir todos os disparos do seu Pixel no Event Manager ou através do Analytics
              do Facebook e montar seu próprio funil de vendas.</p>
            <p>Para facilitar ainda mais sua vida, seguimos os padrões de Standard Events do Facebook, sendo eles:</p>
            <ul>
              <li>Page View - Usuário faz visualização de página nas páginas da sua loja e páginas de produtos</li>
              <li>AddToCart - Usuário adiciona algum produto ao carrinho</li>
              <li>InitiateCheckout - Usuário começa a preencher dados da primeira etapa do checkout</li>
              <li>AddPaymentInfo - Usuário chega na etapa de preenchimento com dados de pagamento</li>
              <li>Purchase - Usuário conclui a compra com sucesso</li>
            </ul>
            <p>Atenção: De acordo com o próprio Facebook, os eventos podem demorar até 20 minutos para serem atualizados
              em um Pixel.</p>
            <p>A partir de agora você e sua loja tem acesso a uma das ferramentas mais importantes e poderosas do mundo
              do Marketing Digital!</p>
            <p>Abraço de urso!</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="linkDialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})

export default {
  name: 'MarketingPage',
  metaInfo() {
    return {
      title: `Configurações de Marketing - ${config.name} - Beeasy - ${this.userData.fullName}`
    }
  },
  data() {
    return {
      config,
      pixelFbId: '',
      saved: false,
      loading: false,
      verified: true,
      token: localStorage.token,
      urlStore: '',
      userData: {},
      storeData: {},
      node_env: process.env.NODE_ENV,
      linkDialog: false
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      this.pixelFbId = this.storeData.pixelFbId
    },
    async save() {
      this.loading = true;
      try {
        if (!this.pixelFbId) {
          this.$toast.warning('Preencha o campo ID Pixels')
          this.loading = false;
          return
        }

        let updateData = {
          pixel_fb_id: this.pixelFbId
        };

        this.$toast.info('Aguarde...')

        let response = await api.put(`/stores/${this.storeData.id}`, updateData, await this.$store.getters.getConfig);

        localStorage.setItem('store', JSON.stringify(response.data))
        this.$store.commit('update_store', response.data)
        this.$toast.success('Informações salvas com sucesso!')
        this.saved = true;
        this.loading = false;
      } catch (error) {
        console.log(error)
        this.$toast.error('Erro ao salvar informações')
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
  margin-bottom: 1rem;
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
  margin-top: 1rem;
}

.button-default:hover {
  background-color: #008f63;
}

.disabled {
  background-color: #be95aea2;
  border: 1px solid #be95aea2;
  cursor: not-allowed;
}

.container-page {
  padding: 20px;
}

.dialog-content {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .container-page {
    padding-right: 12px;
  }
}
</style>
