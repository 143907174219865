<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2>Logs de Acessos</h2>
          </v-col>
          <v-col cols=12>
            <v-data-table dense dark :headers="headers" :items="logs" :items-per-page="100000" class="elevation-1">
              <template v-slot:[`item.description`]="{ item }">
                [{{ new Date(item.createdAt).toLocaleString('pt-BR') }}] {{item.description}} 
              </template>
            </v-data-table>
            <v-pagination v-model="page" :length="lastPage"></v-pagination>

          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Loading v-if="loadingPage" />
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})


export default {
  name: 'AdmLogsPage',
  metaInfo() {
    return {
      title: `Logs de Acesso - Bruno Graminha - Beeasy - ${this.userData.fullName}`
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      headers: [
        { text: '', value: 'description', align: 'left', }
      ],
      logs: [],
      lastPage: 0,
      userData: {},
      storeData: {},
      loadingPage: false,
    }
  },
  async mounted() {
    await this.init()
    //REFRESH LOGS EVERY 5 SECOnds
    setInterval(() => {
      this.getLogs()
    }, 10000)
  },
  watch: {
    page() {
      this.getLogs()
    }
  },
  methods: {
    async init() {
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      await this.getLogs()
      
    },
    async getLogs() {
      try {
        const { data } = await api.get(`/logs/${this.page}`, await this.$store.getters.getConfig)
        this.logs = data.data
        this.lastPage = data.meta.lastPage
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #f70293;
  border: 1px solid #f70293;
  cursor: pointer;
}

.column-pos {
  text-align: center;
  color: rgb(55 65 81);
  ;
  padding: .5rem;
}

.column-title {
  font-size: 1rem;
  font-weight: 400;
}

.column-subtitle {
  font-size: .8rem;
  font-weight: 400;
}
</style>
