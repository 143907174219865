<template>
    <v-container class="container-page">
        <v-row>
            <v-col cols="12" md="12">
                <v-row>
                    <v-col cols="12">
                        <h2>Bem-vindo! Pronto para começar?</h2>
                        <p class="text-gray-500">Vamos dar os primeiros passos para realizar o seu sonho de uma marca de
                            sucesso? Separamos algumas dicas para te ajudar a criar a sua loja.</p>
                    </v-col>
                    <v-col>
                        <v-stepper v-model="step" vertical>
                            <v-stepper-step :complete="step > 1" :color="stepColor" step="1">
                                Importar produtos
                            </v-stepper-step>

                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12">
                                        <h2>Trazer produtos para sua loja</h2>
                                        <p>Sem produtos, não há o que vender. Por isso, vamos começar realizando a
                                            importação dos produtos para sua loja!</p>
                                        <p>Não se preocupe, fazemos tudo para você, basta clicar no botão abaixo</p>

                                    </v-col>
                                    <v-col>
                                        <v-btn rounded dark :color="stepColor" @click="mImport = true">Importar
                                            produtos</v-btn>
                                    </v-col>
                                </v-row>
                                <br>
                                <br>
                            </v-stepper-content>

                            <v-stepper-step :complete="step > 2" :color="stepColor" step="2">
                                Personalize sua loja
                            </v-stepper-step>

                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12">
                                        <h2>Personalize sua loja</h2>
                                        <p>
                                            Que tal trazer mais vida e cores para a sua loja, além de deixar ela a cara
                                            da sua marca?
                                        </p>

                                    </v-col>
                                    <v-col>
                                        <v-btn rounded dark :color="stepColor"
                                            @click="$router.push('/store')">Personalize sua loja</v-btn>
                                    </v-col>
                                </v-row>
                                <br>
                                <br>
                            </v-stepper-content>
                        </v-stepper>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog v-model="mImport" persistent max-width="400">
            <v-card>
                <v-card-title class="text-h5">
                    Deseja realizar a importação dos produtos?
                </v-card-title>
                <v-card-text>
                    Ao clicar em "SIM" você concorda com a importação dos produtos do <strong>{{config.name}}</strong>
                    para sua loja.
                    <br><br>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#606060" text @click="mImport = false">
                        NÃO
                    </v-btn>
                    <v-btn :color="stepColor" text @click="importProducts">
                        SIM
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="mLoading" persistent width="50%">
            <v-card :color="stepColor" dark>
                <v-card-text>
                    Estamos trazendo todos os produtos do {{config.name}} para sua loja. Aguarde...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="mSucess" persistent max-width="500">
            <v-card>
                <v-card-title class="text-h6">
                    Prontinho, seus produtos foram importados com sucesso!
                </v-card-title>
                <v-card-text>
                    Agora você já pode começar a vender os produtos do {{config.name}} em sua loja.

                    Clique abaixo para continuar o processo de configuração da sua loja.


                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <button class="button-default" @click="mSucess = false; $emit('updatestep', 2)">
                        Entendi
                    </button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})

export default {
    name: 'Steps',
    metaInfo: {
        title: `{{config.name}} - Beeasy - ${process.env.NODE_ENV}`,
    },
    props: {
        userData: Object,
        storeData: Object,
        step: Number
    },
    data() {
        return {
            config,
            profit: 100,
            stepColor: '#00AF7D',
            loading: false,
            mImport: false,
            mLoading: false,
            mSucess: false
        }
    },
    methods: {
        async importProducts() {
            if (this.profit <= 0) {
                this.$toast.warning('Informe um valor de lucro válido')
                return
            }

            this.mImport = false
            this.mLoading = true
            try {
                await api.post(`/store/${this.storeData.id}/products/import`, { profit: this.profit })
                this.mLoading = false
                this.mSucess = true
            } catch (error) {
                this.mLoading = false
            }
        }
    }
}
</script>

<style scoped>
* {
    font-family: "Outfit", sans-serif;
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
    width: 100%;
    padding: .5rem;
    color: #404049;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(64, 64, 73, .3);

}

.button-default {
    width: 100%;
    padding: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    background-color: #00AF7D;
    border: 1px solid #00AF7D;
    cursor: pointer;
}
</style>