<template>
    <div class="card">
        <v-container class="container">
            <v-row>
                <v-col>
                    <img :src="iconShow" alt="">
                </v-col>
                <v-col>
                    <p>{{title}}</p>
                    <h3>{{value}}</h3>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    name: "CardInfo",
    data() {
        return {
            iconShow: '',
        }
    },
    props: {
        userData: Object,
        storeData: Object,
        icon: String,
        title: String,
        value: String,
    },
    created(){
        this.iconShow = require(`../assets/icons/sidebar/v2/${this.icon}`)
    
    }
}
</script>

<style scoped>
    *{
        font-family: "Questrial", sans-serif;
    }

    img{
        width: 50px;
        display: block;
        margin: 0 auto;
        margin-top: 3.5vh;
    }

    .card{
        background-color: #f9f9f8;
        border-radius: 10px;
        padding: 10px;
        margin: 10px;
        color: #3A3A3A
    }

    p{
        margin-top: 3.5vh;
        font-size: 1rem;
        font-weight: 400;
        color: #3A3A3A;
    }

    h3{
        font-size: 1.7rem;
        font-weight: 500;
        color: #3A3A3A;
        margin-top: -20px;
    }

</style>