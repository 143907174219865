<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h2>Produtos</h2>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>
                <v-container fluid>
                  <v-row align="center" class="align-center">
                    <v-col cols="12" md="4" class="d-flex justify-center justify-md-start">
                      <button class="button-default" @click="mMassEdit = true;">
                        <v-badge color="green" content="Novo" inline>
                          ALTERAÇÃO EM MASSA
                        </v-badge>
                      </button>
                    </v-col>
                    <v-col cols="12" md="4">
                      <div class="form-item">
                        <label>Pesquisar</label>
                        <input type="text" class="input-default" @keyup="searchProduct" v-model="term"
                          placeholder="Pesquisar por nome">
                      </div>
                    </v-col>
                    <v-col cols="12" md="4">
                      <label for="category">Categoria</label>
                      <select class="input-default" v-model="filters.category" @change="getProducts">
                        <option value="">NENHUMA</option>
                        <optgroup v-for="category in categories" :key="category.id" :label="category.name">
                          <option :value="category.id">{{ category.name }}</option>
                          <option v-for="subcategory in category.subcategories" :key="subcategory.id"
                            :value="subcategory.id">
                            {{ subcategory.name }}
                          </option>
                        </optgroup>
                      </select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="3" v-for="product in productsFiltered" :key="product.id">
                      <ProductCard :product="product" @edit="prepareEdit" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <EditProduct :productSelected="productSelected" :mEdit="mEdit" :loading="loading" @saveProduct="saveProduct"
      @closeModal="closeEditModal" />

    <v-dialog v-model="mMassEdit" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Alteração em Massa</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <label>Categoria</label>
                <select class="input-default" v-model="massUpdateData.category">
                  <option value="" disabled selected>Selecione uma categoria</option>
                  <optgroup v-for="category in categories" :key="category.id" :label="category.name">
                    <option :value="category.id">{{ category.name }}</option>
                    <option v-for="subcategory in category.subcategories" :key="subcategory.id" :value="subcategory.id">
                      {{ subcategory.name }}
                    </option>
                  </optgroup>
                </select>
                <label>Porcentagem de Lucro</label>
                <input type="number" v-model="massUpdateData.profit" class="input-default">
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <button class="button-link" @click="mMassEdit = false">
            Cancelar
          </button>
          <button class="button-default" @click="massUpdate" v-if="!loading">
            Salvar
          </button>
          <button class="button-default" v-if="loading">
            Aguarde...
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading v-if="loading" />
    <Loading v-if="loadingProducts" />
  </v-container>
</template>

<script>
import EditProduct from '@/components/Product/EditProduct.vue';
import ProductCard from '@/components/Product/ProductCard.vue';
import Loading from '@/components/Loading.vue';
import axios from 'axios';
import config from '@/store/config';

const api = axios.create({
  baseURL: config.baseURL
});

export default {
  name: 'ProductsPage',
  components: {
    Loading,
    EditProduct,
    ProductCard
  },
  data() {
    return {
      term: '',
      loading: false,
      loadingProducts: false,
      userData: {},
      categories: [],
      products: [],
      productsFiltered: [],
      old_sale_price: 0,
      massUpdateData: {
        category: '',
        profit: 0
      },
      filters: {
        limit: 400,
        category: null,
      },
      mEdit: false,
      mMassEdit: false,
      productSelected: {},
    };
  },
  async mounted() {
    await this.init();
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de produtos.`})
  },
  methods: {
    async init() {
      this.loading = true;
      this.userData = this.$store.getters.getUserData;
      await this.getCategories();
      await this.getProducts();
      this.loading = false;
    },
    async getCategories() {
      try {
        const response = await api.get('/categories', await this.$store.getters.getConfig);
        this.categories = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getProducts() {
      try {
        this.mEdit = false;
        this.loadingProducts = true;
        this.filters.category = this.filters.category === '' ? null : this.filters.category;
        if (this.filters.category === null) {
          this.filters.limit = 24;
        }else{
          this.filters.limit = 400;
        }
        const response = await api.post(`/store/${this.userData.idStore}/products`, this.filters, await this.$store.getters.getConfig);
        this.products = response.data;
        this.productsFiltered = response.data;
        this.loadingProducts = false;
      } catch (error) {
        console.error(error);
      }
    },
    searchProduct() {
      this.productsFiltered = this.products.filter(product => product.name.toLowerCase().includes(this.term.toLowerCase()));
    },
    prepareEdit(product) {
      this.productSelected = product;
      this.mEdit = true;
      this.old_sale_price = product.sale_price;
      this.$store.commit('insertLog',{ action: 'Acao',  description: `[${this.userData.fullName}] clicou em editar produto [${product.name}] {${product.id}}.`})
    },
    closeEditModal() {
      this.mEdit = false;
    },
    async saveProduct() {
      try {
        this.loading = true;
        await api.put(`/store/${this.userData.idStore}/products/${this.productSelected.store_product_id}`, { sale_price: this.productSelected.sale_price }, await this.$store.getters.getConfig);
        this.updateProductList();
        this.mEdit = false;
        this.loading = false;
        this.$toast.success('Produto salvo com sucesso!');
        this.$store.commit('insertLog',{ action: 'Atualizacao',  description: `[${this.userData.fullName}] alterou o preço do produto [${this.productSelected.name}] {${this.productSelected.store_product_id}} de ${this.old_sale_price} para ${this.productSelected.sale_price}`})
      } catch (error) {
        console.error(error);
      }
    },
    updateProductList() {
      this.productsFiltered = this.productsFiltered.map(product => {
        if (product.store_product_id === this.productSelected.store_product_id) {
          product.sale_price = this.productSelected.sale_price;
        }
        return product;
      });
      this.products = this.products.map(product => {
        if (product.store_product_id === this.productSelected.store_product_id) {
          product.sale_price = this.productSelected.sale_price;
        }
        return product;
      });
    },
    async massUpdate() {
      if (!this.massUpdateData.category) {
        this.$toast.error('Selecione uma categoria');
        return;
      }
      if (this.massUpdateData.profit === 0) {
        this.$toast.error('Informe a porcentagem de lucro');
        return;
      }
      try {
        this.loading = true;
        this.$toast.info('Aguarde...');
        await api.put(`/store/${this.userData.idStore}/products/${this.massUpdateData.category}/mass`, { profit_percentage: this.massUpdateData.profit }, await this.$store.getters.getConfig);
        this.filters.category = this.massUpdateData.category;
        this.$store.commit('insertLog',{ action: 'Atualizacao',  description: `[${this.userData.fullName}] alterou a porcentagem de lucro da categoria [${this.categories.find(category => category.id === this.massUpdateData.category).name}] para ${this.massUpdateData.profit}%.`})
        this.massUpdateData = {
          category: null,
          profit: 0
        };
        this.mMassEdit = false;
        this.loading = false;
        this.$toast.success('Alteração realizada com sucesso!');
        
        await this.getProducts();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-700 {
  color: rgba(55, 65, 81, 1);
}

.bg-gray-100 {
  background-color: rgba(243, 244, 246, 1);
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.input-default[readonly] {
  background-color: #f2f2f2;
}

.button-default {
  padding: .5rem 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.button-default:hover {
  opacity: .8;
}

.button-link {
  width: 100%;
  padding: .5rem;
  color: #00AF7D;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.button-link:hover {
  background-color: #f2f2f2;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 10px 20px;
  font-size: 1rem;
}

.form-item {
  display: flex;
  flex-direction: column;
}
</style>
