<template>
  <v-col cols="12" md="4" class="text-start">
    <h2>Resumo do pedido</h2>
    <p>Confira abaixo os valores cobrados</p>

    <div class="card-resume text-start">
      <h2>{{ formattedPrice }}</h2>
      <h3>{{ type }}</h3>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "DomainSummary",
  props: {
    price: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedPrice() {
      return this.price.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
  }
};
</script>

<style scoped>
.card-resume {
  background-color: #1c1a1d;
  color: #fff;
  border-radius: 20px;
  padding: 15px;
  text-align: left;
}
</style>
