<template>
    <v-app style="background-color: #f2f3fd !important;">
        <Sidebar />
        <Navbar/>
        <router-view style="margin-top: 4rem" v-if="$store.getters.isMobile"></router-view>
        <router-view class="router-view" v-if="!$store.getters.isMobile"></router-view>
        <!-- <h1 style="position: absolute;z-index: 999999; color: red">{{width}}</h1> -->
    </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';
export default {
    name: 'DashboardLayout',
    components: {
        Sidebar,
        Navbar
    },
    data(){
        return{
        width: window.outerWidth
            
        }
    },
    mounted(){
    },
    methods: {
    }
}
</script>

<style scoped>

.router-view{
    margin-left: 205px;
    margin-top: 4rem
}

@media (min-width: 768px) and (max-width: 1280px){
    .container-page{
        margin-left: 205px;
        margin-right: 205px;
        margin-top: 4rem
    }
}


</style>