<template>
  <v-dialog v-model="detailsDialog" max-width="600">
    <v-card>
      <v-card-title>
        <span class="headline text-gray-700">Detalhes da Transação</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-col><strong>Pedido:</strong> BEE{{ (selectedTransaction.id + "").padStart(6, "0") }}</v-col>
            <v-col><strong>Cliente:</strong> {{ selectedTransaction.name }}</v-col>
            <v-col><strong>Email:</strong> {{ selectedTransaction.email }}</v-col>
            <v-col><strong>Telefone:</strong> {{ selectedTransaction.phone }}</v-col>
            <v-col v-if="selectedTransaction.subtotal !== undefined"><strong>Subtotal:</strong> {{
              formatCurrency(selectedTransaction.subtotal) }}</v-col>
            <v-col v-if="selectedTransaction.shipping !== undefined"><strong>Frete:</strong> {{
              formatCurrency(selectedTransaction.shipping) }}</v-col>
            <v-col v-if="selectedTransaction.total !== undefined"><strong>Total:</strong> {{
              formatCurrency(selectedTransaction.total) }}</v-col>
            <v-divider class="my-4"></v-divider>
            <v-col v-if="selectedTransaction.costPrice !== undefined"><strong>Lucro de Venda:</strong> {{
              formatCurrency(selectedTransaction.subtotal - selectedTransaction.costPrice) }}</v-col>
            <v-col v-if="selectedTransaction.transactionFee !== undefined"><strong>Taxa de Transação:</strong> {{
              formatCurrency(selectedTransaction.transactionFee) }}</v-col>
            <v-col
              v-if="selectedTransaction.costPrice !== undefined && selectedTransaction.transactionFee !== undefined"><strong>Lucro
                Repassado:</strong> {{ formatCurrency((selectedTransaction.subtotal - selectedTransaction.costPrice) -
                  selectedTransaction.transactionFee) }}</v-col>
            <v-col class="text-right" cols="12">
              <v-btn color="#00AF7D" text @click="closeDialog">Entendi</v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TransactionDetailsDialog',
  props: {
    detailsDialog: Boolean,
    selectedTransaction: Object,
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped></style>
